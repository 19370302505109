<template>
    <div>
        <b-row>
            <!-- First Answer Headline -->
            <b-col md="12">
                <b-form-group label="First Answer Headline" label-for="firstAnswerHeadline">
                    <b-form-input type="text" name="firstAnswerHeadline" v-model="prop_siteSettingArray.firstAnswerHeadline" />
                </b-form-group>
            </b-col>

            <!-- First Answer Button Text -->
            <b-col md="12">
                <b-form-group label="First Answer Button Text" label-for="firstAnswerButtonText">
                    <b-form-input type="text" name="firstAnswerButtonText" v-model="prop_siteSettingArray.firstAnswerButtonText" />
                </b-form-group>
            </b-col>

            <!-- Font family -->
            <b-col md="12">
                <b-form-group label="Font family" label-for="firstAnswerFontFamily">
                    <Select2 v-model="prop_siteSettingArray.firstAnswerFontFamily" :options="this.firstAnswerFontFamily" name="questionFontFamily" />
                </b-form-group>
            </b-col>
        </b-row>
    </div>
</template>

<script>
    import { BRow, BCol, BFormInput, BFormGroup, BInputGroup } from 'bootstrap-vue'
    import captureOptions from "@/libs/capture-Options"

    export default {
        components: {
            BRow, BCol, BFormInput, BFormGroup, BInputGroup
        },
        props: {
            prop_siteSettingArray: {
                type: Object,
                default: () => { },
            }
        },
        data() {
            return {}
        },
        methods: {
        },
        created() {
            if (_.isEmpty(this.prop_siteSettingArray.firstAnswerHeadline)) {
                this.prop_siteSettingArray.firstAnswerHeadline = 'Welcome! Lets get started...';
            }
            if (_.isEmpty(this.prop_siteSettingArray.firstAnswerButtonText)) {
                this.prop_siteSettingArray.firstAnswerButtonText = 'Take the tour';
            }
            if (_.isEmpty(this.prop_siteSettingArray.firstAnswerFontFamily)) {
                this.prop_siteSettingArray.firstAnswerFontFamily = 'Open Sans';
            }
        },
        setup() {
            const { firstAnswerFontFamily } = captureOptions();
            return {
                firstAnswerFontFamily
            }
        },
    }
</script>